import LoginMock from 'components/LoginMock'
import React, { useEffect, useRef, useState } from 'react'
import * as styles from './index.module.less'

// const isDevelopment = false
const isDevelopment = process.env.NODE_ENV === 'development'

let SensorsLogin = null;

interface LoginWithFullProps {
  /** 登陆成功后的回调 */
  onLoginSuccess?: Function
  /** 登陆组件的配置 */
  config?: any
}

const LoginWithFull: React.FC<LoginWithFullProps> = ({ onLoginSuccess, config = {} }) => {
  const [visible, setVisible] = useState(true)
  const maskRef = useRef<HTMLDivElement>(null)

  // 登陆成功回调
  const loginSuccessCB = () => {
    setVisible(false)
    onLoginSuccess?.()
    window.location.reload()
  }

  const createLoginComponent = () => {
    const loginContainer = document.createElement('div')
    loginContainer.setAttribute('id', 'sensors-login')

    SensorsLogin?.renderLogin(
      {
        lang: '', // 语言环境，默认中文简体：zh-CN，繁体：zh-TW
        domainPassword: process.env.API_URL, // 登录接口域名配置，可不传，默认：/api/passport，测试环境可配置：/api/_passport
        config,
        onLoginSuccess: loginSuccessCB, // 登录成功回调事件
      },
      loginContainer,
    )

    maskRef?.current?.appendChild(loginContainer)
  }

  useEffect(() => {
    if (!SensorsLogin) {
      SensorsLogin = require('@eefe/sensors-login/dist/sensors-login.js')
    }

    // 禁止滚动穿透
    if (visible) {
      document.body.style.overflow = 'hidden'
      !isDevelopment && createLoginComponent()
    } else {
      document.body.style.overflow = 'auto'
    }

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [visible])

  return visible ? (
    <div className={styles.modalOverlay} ref={maskRef}>
      {isDevelopment && <LoginMock onLoginSuccess={loginSuccessCB}></LoginMock>}
    </div>
  ) : null
}

export default LoginWithFull
