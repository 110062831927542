/** 为了UI而模拟的登陆界面，需要手动传入登陆账户cookie中的passportSession值 */
import React, { useState } from 'react'
import * as styles from './index.module.less'

interface LoginMockProps {
  /** 成功登陆回调方法 */
  onLoginSuccess?: Function
  /** 登陆的session */
  passportSession?: string
}

const LoginMock: React.FC<LoginMockProps> = ({ onLoginSuccess, passportSession = '' }) => {
  const [value, setValue] = useState(passportSession)

  const setPassportCookie = (value, expires = 7) => {
    const name = 'passport_session'
    const domain = window.location.hostname
    const d = new Date()
    d.setTime(d.getTime() + expires * 24 * 60 * 60 * 1000)
    const expiresStr = 'expires=' + d.toUTCString()
    document.cookie = `${name}=${value};${expiresStr};domain=${domain};path=/`
  }

  const onLogin = () => {
    setPassportCookie(value)
    onLoginSuccess?.()
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.passportSession}>
        <span>PassportSession:</span>
        <div className={styles.inputWrapper}>
          <input value={value} onChange={(e) => setValue(e.target.value)} type="text" placeholder="passport_session" />
        </div>
      </div>

      <div className={styles.btn} onClick={onLogin}>
        点击此处登陆
      </div>
    </div>
  )
}

export default LoginMock
